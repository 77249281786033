import { Component, Input, OnInit } from '@angular/core';

import { ApiService, GlobalService, LogService } from '@fm/services';
import { ModalSizes } from '@fmlib/enums';
import { GlobalStore, Tooltip } from '@fmlib/interfaces';
import { TooltipDialogComponent } from './tooltip-dialog/tooltip-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs';

@Component({
	selector: 'tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.less'],
})
export class TooltipComponent implements OnInit {
	@Input() type: string;
	@Input() prop: string;

	global: GlobalStore;

	showTooltip: boolean;
	tooltip: Tooltip;

	helpText: string;

	constructor(
		private dialog: MatDialog,
		private FM_Log: LogService,
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.helpText = this.getHelpText();

		this.buildTooltip();
	}

	buildTooltip(): void {
		this.showTooltip = false;

		this.tooltip = this.global.tooltips.find((t: Tooltip) => {
			return t.objType === this.type && t.objProp === this.prop;
		});

		// if tooltip doesnt exist we create a new one
		if (!this.tooltip) {
			const tt = { objType: this.type, objProp: this.prop };
			this.FM_Log.info('generateTooltip', tt);
			this.FM_Api.connect('tooltips').post(tt, (res) => {
				this.global.tooltips.push(res);
			});
		}
		// if tooltip exist with published data
		else if (this.tooltip?.tooltip !== '') {
			this.showTooltip = true;
		}
	}

	getHelpText(): string {
		return this.type.toUpperCase() + '_' + this.prop.toUpperCase() + '_HELP';
	}

	openTooltipEditor(): void {
		const dialogRef = this.dialog.open(TooltipDialogComponent, {
			panelClass: ModalSizes.Basic,
			data: {
				tooltip: this.tooltip,
			},
		});

		dialogRef.afterClosed().subscribe((results) => {
			if (results) {
				// refresh tooltips
				this.FM_Api.get('tooltips', { per_page: 1000 })
					.pipe(
						tap((res) => {
							this.global.tooltips = res;
							this.buildTooltip();
						})
					)
					.subscribe();
			}
		});
	}

	/*
	goToToolip(): void {
		const titanLink =
			this.global.config.server === 'local'
				? 'https://local.titan.footmarks.com:3200/tooltips'
				: 'https://titan.footmarks.com/tooltips';

		window.open(`${titanLink}?objtype=${this.type}&objprop=${this.prop}`, '_blank');
	}
	*/
}
