<section class="fm-detail-content">
	<div class="form-actions">
		<div class="step-actions">
			<a (click)="publish()">Publish</a>
		</div>
		<a (click)="save()">Save</a>
		<!--<a [routerLink]="" (click)="delete()">Delete</a>-->
	</div>

	<div class="form-details">
		<div class="form-row">
			<label>Type</label>
			<div class="field-info">{{ tooltip.objType }}</div>
		</div>
		<div class="form-row">
			<label>Property</label>
			<div class="field-info">
				{{ tooltip.objProp }}
			</div>
		</div>

		<div class="tooltip-editor">
			<div class="tooltip-preview" *ngIf="tooltip.tooltip">
				<div class="tooltip-status published">Published</div>
				<div [innerHTML]="tooltip.tooltip"></div>
				<div class="field-count">
					<a [routerLink]="" (click)="clearPublished()">Clear Published</a>
				</div>
			</div>

			<div class="tooltip-preview" *ngIf="!tooltip.draft && !view.showEditor">
				<a (click)="showEditor()">edit tooltip</a>
			</div>

			<div class="tooltip-preview" *ngIf="tooltip.draft || view.showEditor">
				<div class="tooltip-status draft">Draft</div>
				<div>
					<ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
					<ngx-editor
						[editor]="editor"
						[(ngModel)]="tooltip.draft"
						[disabled]="false"
						[placeholder]="'Type here...'"></ngx-editor>

					<div class="field-count">
						<a [routerLink]="" (click)="clearEditor()">Clear Draft</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
