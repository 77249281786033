import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { cloneDeep } from 'lodash-es';
import { TooltipEditorComponent } from 'projects/ui-lib/src/components/tooltip-editor/tooltip-editor.component';

@Component({
	standalone: true,
	imports: [FormsModule, TooltipEditorComponent],
	selector: 'tooltip-dialog',
	templateUrl: './tooltip-dialog.component.html',
	styleUrls: ['./tooltip-dialog.component.less'],
})
export class TooltipDialogComponent implements OnInit {
	global: GlobalStore;
	tooltip;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<TooltipDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.tooltip = cloneDeep(this.data.tooltip);
	}

	runUpdate(tooltip): void {
		this.tooltip = tooltip;
		this.ok();
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.tooltip);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
