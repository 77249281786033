// ANGULAR
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
// import { ConfirmDialogComponent } from '../../confirm/confirm-dialog.component';

import { ApiService, MessageService } from '@fm/services';
import { Tooltip } from '@fmlib/interfaces';

import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	imports: [CommonModule, FormsModule, RouterModule, NgxEditorModule],
	selector: 'lib-tooltip-editor',
	templateUrl: './tooltip-editor.component.html',
	styleUrls: ['./tooltip-editor.component.less'],
})
export class TooltipEditorComponent implements OnChanges, OnDestroy {
	@Input() tooltip: Tooltip;

	@Output() update = new EventEmitter();

	view;

	editor: Editor;
	toolbar: Toolbar = [
		// default value
		['bold', 'italic'],
		['underline', 'strike'],
		['code', 'blockquote'],
		['ordered_list', 'bullet_list'],
		[{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
		// ['link', 'image'],
		// or, set options for link:
		//[{ link: { showOpenInNewTab: false } }, 'image'],
		// ['text_color', 'background_color'],
		['align_left', 'align_center', 'align_right', 'align_justify'],
		// ['horizontal_rule', 'format_clear'],
	];

	constructor(
		public dialog: MatDialog,
		private FM_Api: ApiService,
		private FM_Messsage: MessageService
	) {}

	ngOnChanges(): void {
		this.view = {
			showEditor: false,
			failed: false,
		};

		this.editor = new Editor();
	}

	// EDITOR
	showEditor(): void {
		this.view.showEditor = true;
	}

	clearEditor(): void {
		this.view.showEditor = false;
		this.tooltip.draft = '';
	}

	// SAVE/DELETE
	publish(): void {
		this.view.showEditor = false;

		const temp = { tooltip: this.tooltip.draft, draft: '' };

		this.FM_Api.connect('tooltips')
			.put({ id: this.tooltip.id }, temp)
			.subscribe((response) => {
				this.tooltip.tooltip = this.tooltip.draft;
				this.tooltip.draft = '';
				this.update.emit(response);
				this.FM_Messsage.addSuccessMessage('Your tooltip has been published');
			});
	}

	clearPublished(): void {
		const temp = { tooltip: '' };

		this.FM_Api.connect('tooltips')
			.put({ id: this.tooltip.id }, temp)
			.subscribe((response) => {
				this.tooltip = response;
				this.update.emit(response);
				this.FM_Messsage.addSuccessMessage('Your tooltip has been unpublished');
			});
	}

	save(): void {
		const temp = {
			draft: this.tooltip.draft,
			tooltip: this.tooltip.tooltip,
		};

		this.FM_Api.connect('tooltips')
			.put({ id: this.tooltip.id }, temp)
			.subscribe((response) => {
				this.tooltip = response;
				this.update.emit(response);
				this.FM_Messsage.addSuccessMessage('Your tooltip has been saved');
			});
	}

	/*
	delete(): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			panelClass: 'modal-confirm',
			data: {
				message: 'DELETE_TOOLTIP',
			},
		});

		dialogRef.afterClosed().subscribe((results) => {
			if (results) {
				this.FM_Api.connect('tooltips').delete({ id: this.tooltip.id }, () => {
					this.list.initSearch();
				});
			}
		});
	}
		*/

	// make sure to destory the editor
	ngOnDestroy(): void {
		this.editor.destroy();
	}
}
