import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Event, NavigationStart, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

// SOCKET IO
import { io } from 'socket.io-client';

import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable, forkJoin, timer } from 'rxjs';

import { ModalSizes } from '@fmlib/enums';

import {
	ApiService,
	AuthService,
	BroadcastService,
	GlobalService,
	LogService,
	MenuService,
	MessageService,
	SettingsService,
	ToolsService,
} from '@fm/services';

import { Company, Environment, GlobalStore, Language } from '@fmlib/interfaces';

export enum LoginState {
	EXPIRED = 'expired',
	LOADING = 'loading',
	LOGIN = 'login',
	MFA = 'mfa',
	RESET = 'reset',
	VERIFY = 'verify',
}

export interface AppView {
	loginPanel: string;

	loading_message?: string;
	login_message?: {
		title: string;
		message: string;
	};

	showLogin: boolean;
	showMain: boolean;
	showMainMenu: boolean;
	showNews: boolean;
}

@Injectable({ providedIn: 'root' })
export class AppService {
	global: GlobalStore;

	_self;

	params: string[];
	view: AppView;
	changingCompany: boolean;
	newCompany: Company;
	isBasic: boolean;
	resetToken: string;

	private _gtagLoaded: boolean;
	private _targetUrl: string;

	readonly LOAD_DELAY = 1000;

	get currentRoute(): string {
		return this.params[0] ? this.params[0].toLowerCase() : '';
	}

	constructor(
		private location: Location,
		private router: Router,
		private dialog: MatDialog,
		private FM_Api: ApiService,
		private FM_Auth: AuthService,
		private FM_Broadcast: BroadcastService,
		private FM_Global: GlobalService,
		private FM_Log: LogService,
		private FM_Menu: MenuService,
		private FM_Messages: MessageService,
		private FM_Settings: SettingsService,
		private FM_Tools: ToolsService
	) {}

	init(): void {
		this.view = {
			loginPanel: 'loading',

			showLogin: false,
			showMain: false,
			showMainMenu: false,
			showNews: false,
		};

		// CHECK PATH FOR RESET...
		const loc = this.location.path();

		const route_regex = /\?|\//;
		this.params = loc ? loc.substring(1).split(route_regex) : [];

		this.FM_Broadcast.authError.subscribe((error) => {
			this.FM_Log.info('authError', error);

			if (error) {
				this.clearMessage();

				// clear the token
				this.FM_Auth.clearToken();

				switch (error.name) {
					case 'Expired':
						this.clearMessage();
						break;
					case 'Invalid2FACode':
						this.setMessage('MFA_CODE_ERROR');
						break;
					case 'BadUsername':
					case 'BadPassword':
						this.setMessage('LOGIN_PASSWORD_ERROR');
						this.initGlobal();
						break;
					case 'Lockout':
						this.setMessage('LOGIN_LOCKOUT_ERROR');
						this.initGlobal();
						break;
					case 'ForbiddenIp':
						this.setMessage('LOGIN_IP_ERROR');
						this.initGlobal();
						break;
					default:
						this.setMessage('LOGIN_TOKEN_ERROR');
						this.initGlobal();
						break;
				}
			}
		});

		this.FM_Broadcast.responseError.subscribe((error) => {
			this.FM_Log.info('responseError', error);

			switch (error.status) {
				case 0:
					this.FM_Messages.addTimeoutMessage();
					break;
				case 403:
					this.FM_Messages.addErrorMessage('No access to this item');
					break;
				default:
					break;
			}
		});

		// IF COMPANY IS CHANGED
		this.FM_Global.onSwitch().subscribe(() => {
			if (this.global && this.global.switchCompany) {
				this.switchCompany(this.global.switchCompany);
			}
		});

		// IF USER IS LOGGED OUT
		this.FM_Global.onLogout().subscribe((reason: string) => {
			if (reason === 'invalid_id') {
				this.setMessage('COMPANY_AUTH_ERROR');
			}
			this.logout();
		});

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				this._targetUrl = event.url;
				this.view.showMainMenu = false;
			}
		});

		this.initGlobal();
	}

	resetLogin(): void {
		console.log('resetLogin', this._targetUrl);

		this.params = [];

		this.clearMessage();
		this.setView(LoginState.LOGIN);

		this._targetUrl = '/';
		this.router.navigate(['/']);
	}

	clearMessage(): void {
		delete this.view.login_message;
		delete this.view.loading_message;
	}

	setMessage(type: string): void {
		this.view.login_message = {
			title: type,
			message: type + '_MESSAGE',
		};
	}

	loadSettings(): Observable<any> {
		const obs$ = [];
		const PARTNER_COUNT = 350;

		// SET UP OVERVIEW
		this.global.overview = this.FM_Global.getCounts();

		// LOAD TO PARTNERS
		obs$.push(
			this.FM_Api.get('partners', {
				per_page: PARTNER_COUNT,
				populate: JSON.stringify({
					path: 'partner',
					select: 'id name image',
				}),
			}).pipe(
				tap((res) => {
					this.global.partners.to = res;
				})
			)
		);

		// LOAD FROM PARTNERS
		obs$.push(
			this.FM_Api.get('partners', {
				per_page: PARTNER_COUNT,
				populate: JSON.stringify({
					path: 'company',
					select: 'id name image',
				}),
				conditions: JSON.stringify({
					partner: this.global.company.id,
				}),
			}).pipe(
				tap((res) => {
					this.global.partners.from = res;
				})
			)
		);

		// LOAD COMPANY AND USER SETTINGS
		obs$.push(this.FM_Settings.getCompanySettings());
		obs$.push(this.FM_Settings.getUserSettings());

		// LOAD TOOLTIPS
		obs$.push(
			this.FM_Api.get('tooltips', { per_page: 1000 }).pipe(
				tap((res) => {
					this.global.tooltips = res;
				})
			)
		);

		return forkJoin(obs$);
	}

	// CHECK IF USER HAS AUTH
	checkAuth(): void {
		this.global.authenticated = this.FM_Auth.hasToken();

		if (this.global.authenticated) {
			this.setView(LoginState.LOADING);
			this.view.loading_message = 'LOADING';

			timer(this.LOAD_DELAY).subscribe(() => {
				this.view.loading_message = 'VALIDATING_USER';
				this.FM_Api.me()
					.pipe(
						catchError(() => {
							// ME ERROR
							this.FM_Log.info('me error');
							this.setView(LoginState.LOGIN);

							return EMPTY;
						})
					)
					.subscribe((response) => {
						timer(this.LOAD_DELAY).subscribe(() => {
							if (this.FM_Auth.checkCompany(response.company.id)) {
								// SET COMPANY AND USER
								this.FM_Global.setCompany(response.company);
								this.FM_Global.setUser(response.adminUser);

								// BEGIN SOCKET
								this.initSocket();

								this.global.cdn = {
									external: response.cdnEndpoint,
									internal: response.cdnEndpointInternal,
								};

								this.global.permissions = response.sessionPermissions;
								this.global.permissionFilters = response.groupFilters;

								this.view.loading_message = 'LOADING_SETTINGS';

								timer(this.LOAD_DELAY).subscribe(() => {
									this.loadSettings()
										.pipe(
											catchError(() => {
												this.setMessage('LOGIN_TOKEN_ERROR');
												this.logout();
												return EMPTY;
											})
										)
										.subscribe(() => {
											this.view.loading_message = 'WELCOME';

											// UPDATE MAIN MENU
											this.FM_Menu.update();
											timer(this.LOAD_DELAY).subscribe(() => {
												delete this.view.loading_message;
												this.view.showLogin = false;
												this.view.showMain = true;

												// TRIGGER MFA SETUP
												if (
													this.global.user.isSuper &&
													!this.global.user.require2FA
												) {
													this.setupMFA();
												}

												if (this._targetUrl === '/') {
													this.router.navigate(['dashboard']);
												} else {
													this.router.navigateByUrl(this._targetUrl);
												}
											});
										});
								});
							} else {
								this.FM_Log.info('company id error');
								this.FM_Global.logout('invalid_id');
							}
						});
					});
			});
		} else {
			this.setView(LoginState.LOGIN);
			this.view.showLogin = true;
			this.view.showMain = false;
			this.location.go('/');
		}
	}

	async setupMFA(): Promise<void> {
		const { MFADialogComponent } = await import(
			/* webpackPrefetch: true */
			'../components/mfa/mfa-dialog.component'
		);

		const dialogRef = this.dialog.open(MFADialogComponent, {
			panelClass: ModalSizes.Basic,
			data: {
				adminuser: this.global.user,
			},
		});

		dialogRef.afterClosed().subscribe((active) => {
			if (active) {
				this.FM_Api.connect('adminusers')
					.put({ id: this.global.user.id }, { require2FA: true })
					.subscribe(() => {
						this.global.user.require2FA = true;
						this.FM_Messages.addSuccessMessage('MFA_SETUP_COMPLETE');
					});
			}
		});
	}

	initGoogle(): void {
		// ADD GOOGLE SCRIPT
		if (
			!this._gtagLoaded &&
			(this.global.config.current === Environment.PRODUCTION ||
				this.global.config.current === Environment.PRODUCTIONEU)
		) {
			const gtag_id = 'G-H50GJD87ZP';

			// register google tag manager
			const gTagManagerScript = document.createElement('script');
			gTagManagerScript.async = true;
			gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtag_id}`;
			document.head.appendChild(gTagManagerScript);

			// register google analytics
			const gaScript = document.createElement('script');
			gaScript.innerHTML = `
			window.dataLayer = window.dataLayer || [];
			  function gtag() { dataLayer.push(arguments); }
			  gtag('js', new Date());
			  gtag('config', '${gtag_id}');
			`;
			document.head.appendChild(gaScript);

			this._gtagLoaded = true;
		}
	}

	initGlobal(): void {
		this.view.showLogin = true;
		this.view.showMain = false;
		this.view.loading_message = 'LOADING';

		this.FM_Global.init().then(() => {
			// SET CONFIG AND INIT API
			this.global = this.FM_Global.get();

			// FORCE CLEAR GLOBAL USER
			this.global.user = null;

			this.FM_Log.info('global : init', this.global);

			// INIT API AND AUTH WITH GLOBAL
			this.FM_Auth.init();
			this.FM_Api.init();

			this.global.authenticated = this.FM_Auth.hasToken();

			// set the local
			// this.tmhDynamicLocale.set(this.global.language.key);

			// SPECIAL PAGES
			if (
				(this.currentRoute === 'gwstatus' || this.currentRoute === 'viewdata') &&
				this.params[1]
			) {
				this.isBasic = true;
			} else if (this.currentRoute === 'setpassword') {
				this.setView(LoginState.RESET);
				this.resetToken = this.params[1];
			} else if (this.currentRoute === 'resetpassword') {
				this.setView(LoginState.RESET);
				this.resetToken = this.params[1];
			} else if (this.currentRoute === 'expirepassword') {
				this.setView(LoginState.EXPIRED);
			} else {
				this.checkAuth();
			}

			if (!this.global.branding) {
				this.FM_Global.setBranding();
			} else {
				this.FM_Global.updateBranding();
			}

			this.initGoogle();
		});
	}

	setView(view: LoginState): void {
		this.view.loginPanel = view;
	}

	logout(): void {
		this.FM_Auth.logout()
			.pipe(
				catchError(() => {
					// ERROR. Re-init globals
					this.initGlobal();

					return EMPTY;
				}),
				tap(() => {
					if (this.global.company?.logoutUri && this.global.company.logoutUri !== '') {
						window.location.assign(this.global.company.logoutUri);
					} else {
						// init global
						this.initGlobal();
					}
				}),
				finalize(() => {
					// CLOSE THE SOCKET
					this.global.socket?.disconnect();
				})
			)
			.subscribe();
	}

	stopSwitching(): void {
		this.changingCompany = false;
		this.newCompany = null;
	}

	switchCompany(id: string): void {
		this.changingCompany = true;

		this.FM_Auth.switchCompany(id)
			.pipe(
				catchError(() => {
					this.FM_Log.info('swap error');
					this.FM_Messages.addSuccessMessage('Error changing company : please try again');
					this.stopSwitching();

					return EMPTY;
				})
			)
			.subscribe(() => {
				this.FM_Tools.switchCompany(id);

				// DISCONNECT OLD SOCKET
				this.global.socket.disconnect();

				timer(this.LOAD_DELAY).subscribe(() => {
					this.FM_Api.me()
						.pipe(
							catchError(() => {
								this.FM_Log.info('me error');

								this.stopSwitching();

								return EMPTY;
							})
						)
						.subscribe((response) => {
							if (this.FM_Auth.checkCompany(response.company.id)) {
								// BEGIN SOCKET
								this.initSocket();

								this.newCompany = response.company;

								timer(this.LOAD_DELAY).subscribe(() => {
									// SET NEW PERMISSIONS
									this.global.permissions = response.sessionPermissions;
									this.FM_Global.setCompany(response.company);

									this.loadSettings().subscribe(() => {
										this.FM_Messages.addSuccessMessage(
											`Your company has been set to ${this.global.company.name}`
										);
										this.FM_Menu.update();

										this.router.navigate(['dashboard']);

										this.changingCompany = false;
										this.newCompany = null;
									});
								});
							} else {
								this.stopSwitching();
								this.FM_Global.logout('invalid_id');
							}
						});
				});
			});
	}

	setLanguage(lang: Language): void {
		this.FM_Global.setLanguage(lang);
	}

	initSocket(): void {
		this.FM_Log.info('socket : init');

		this.global.socket = io(this.global.config.endpoints.socket, {
			transports: ['websocket'],
			query: {
				authorization: this.global.config.token,
			},
		});

		this.global.socket.on('message', (data) => {
			this.FM_Log.info('socket message : ', data);
		});

		this.global.socket.on('login', (data) => {
			this.FM_Log.info('socket login : ', data);
		});

		this.global.socket.on('error', (e) => {
			this.FM_Log.info('socket : error', e);
		});

		this.global.socket.on('connect_error', (e) => {
			this.FM_Log.info(`connect_error due to ${e.message}`);
		});

		// HERE WE WATCH FOR A RECONNECT
		this.global.socket.on('reconnect', () => {
			this.FM_Log.info('socket : reconnect');
		});
	}
}
